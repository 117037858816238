<template>
	<div>
		<div class="handle-box" style="margin-bottom: 20px;">
			<el-button size="small" type="primary" :class="[theme]" icon="el-icon-plus" @click="handleSearch" v-if="addBtn">添加</el-button>
			<el-button size="small" type="primary" :class="[theme]" @click="editForm">编辑修改</el-button>
		</div>
		<el-form ref="lform" :model="form" label-width="120px" class="OEM-form">
			<div style="display: flex;flex-direction: row;">
				<el-form-item label="网站名称" class="l"><el-input v-model="form.webName" :disabled="eiteTrue"></el-input></el-form-item>
				<el-form-item label="个人信息清单" class="r"><el-input v-model="form.info" :disabled="eiteTrue"></el-input></el-form-item>
			</div>
			<div style="display: flex;flex-direction: row;">
				<el-form-item label="网站地址" class="l"><el-input v-model="form.url" :disabled="eiteTrue"></el-input></el-form-item>
				<el-form-item label="第三方sdk" class="r"><el-input v-model="form.sdk" :disabled="eiteTrue"></el-input></el-form-item>
			</div>
			<div style="display: flex;flex-direction: row;">
				<el-form-item label="域名备案号" class="l"><el-input v-model="form.publicSecurity" :disabled="eiteTrue"></el-input></el-form-item>
				<el-form-item label="隐私条款" class="r"><el-input v-model="form.privacy" :disabled="eiteTrue"></el-input></el-form-item>
			</div>
			<div style="display: flex;flex-direction: row;">
				<el-form-item label="域名备网址" class="l"><el-input v-model="form.securityUrl" :disabled="eiteTrue"></el-input></el-form-item>
				<el-form-item label="服务协议" class="r"><el-input v-model="form.agreement" :disabled="eiteTrue"></el-input></el-form-item>
			</div>
			<div style="display: flex;flex-direction: row;">
				<el-form-item label="版权信息" class="l"><el-input v-model="form.information" :disabled="eiteTrue"></el-input></el-form-item>
				<el-form-item label="隐私摘要" class="r"><el-input v-model="form.privacyAbstract" :disabled="eiteTrue"></el-input></el-form-item>
			</div>
			<div style="display: flex;flex-direction: row;">
				<el-form-item label="小程序是否启用" class="l">
					<el-radio-group v-model="form.iphone" :disabled="eiteTrue">
						<el-radio :label="true">开启</el-radio>
						<el-radio :label="false">关闭</el-radio>
					</el-radio-group>
				</el-form-item>
				<el-form-item label="安卓是否启用" class="r">
					<el-radio-group v-model="form.android" :disabled="eiteTrue">
						<el-radio :label="true">开启</el-radio>
						<el-radio :label="false">关闭</el-radio>
					</el-radio-group>
				</el-form-item>
			</div>
			<div style="display: flex;flex-direction: row;">
				<el-form-item label="APP下载地址" prop="downUrl">
					<el-upload
						:disabled="eiteTrue"
						:action="downUrl"
						:limit="1"
						list-type="picture-card"
						:file-list="fileList7"
						:auto-upload="false"
						ref="upload7"
						accept="image/png,image/jpg,image/jpeg"
						:on-change="getdownUrl"
						:class="{ hide: hideUpload7 }"
						:on-preview="handlePictureCardPreview7"
						:on-remove="handleRemove7"
						:before-remove="beformRemove7"
					>
						<el-button size="small" type="primary" :class="[theme]">点击上传</el-button>
					</el-upload>
					<el-dialog v-model="addVisible7"><img :src="dialogImageUrl7" style="width: 100%;" /></el-dialog>
				</el-form-item>
				<el-form-item label="浏览器icon" prop="icon">
					<el-upload
						:disabled="eiteTrue"
						:action="icon"
						:limit="1"
						list-type="picture-card"
						:file-list="fileList3"
						:auto-upload="false"
						ref="upload3"
						accept="image/png,image/jpg,image/jpeg"
						:on-change="geticon"
						:class="{ hide: hideUpload3 }"
						:on-preview="handlePictureCardPreview3"
						:on-remove="handleRemove3"
						:before-remove="beformRemove3"
					>
						<el-button size="small" type="primary" :class="[theme]">点击上传</el-button>
					</el-upload>
					<el-dialog v-model="addVisible3"><img :src="dialogImageUrl3" style="width: 100%;" /></el-dialog>
				</el-form-item>
				<el-form-item label="登录页图片" prop="loginPicture">
					<el-upload
						:disabled="eiteTrue"
						:action="loginPicture"
						:limit="1"
						list-type="picture-card"
						:file-list="fileList4"
						:auto-upload="false"
						ref="upload4"
						accept="image/png,image/jpg,image/jpeg"
						:on-change="getloginPicture"
						:class="{ hide: hideUpload4 }"
						:on-preview="handlePictureCardPreview4"
						:on-remove="handleRemove4"
						:before-remove="beformRemove4"
					>
						<el-button size="small" type="primary" :class="[theme]">点击上传</el-button>
					</el-upload>
					<el-dialog v-model="addVisible4"><img :src="dialogImageUrl4" style="width: 100%;" /></el-dialog>
				</el-form-item>
				<el-form-item label="首页小图片" prop="topSmallPicture">
					<el-upload
						:disabled="eiteTrue"
						:action="topSmallPicture"
						:limit="1"
						list-type="picture-card"
						:file-list="fileList5"
						:auto-upload="false"
						ref="upload5"
						accept="image/png,image/jpg,image/jpeg"
						:on-change="gettopSmallPicture"
						:class="{ hide: hideUpload5 }"
						:on-preview="handlePictureCardPreview5"
						:on-remove="handleRemove5"
						:before-remove="beformRemove5"
					>
						<el-button size="small" type="primary" :class="[theme]">点击上传</el-button>
					</el-upload>
					<el-dialog v-model="addVisible5"><img :src="dialogImageUrl5" style="width: 100%;" /></el-dialog>
				</el-form-item>
				<el-form-item label="首页大图片" prop="topBigPicture">
					<el-upload
						:disabled="eiteTrue"
						:action="topBigPicture"
						:limit="1"
						list-type="picture-card"
						:file-list="fileList6"
						:auto-upload="false"
						ref="upload6"
						accept="image/png,image/jpg,image/jpeg"
						:on-change="gettopBigPicture"
						:class="{ hide: hideUpload6 }"
						:on-preview="handlePictureCardPreview6"
						:on-remove="handleRemove6"
						:before-remove="beformRemove6"
					>
						<el-button size="small" type="primary" :class="[theme]">点击上传</el-button>
					</el-upload>
					<el-dialog v-model="addVisible6"><img :src="dialogImageUrl6" style="width: 100%;" /></el-dialog>
				</el-form-item>
			</div>
			<div style="display: flex;flex-direction: row;">
				<el-form-item label="小程序二维码" prop="iphoneUrl" v-if="form.iphone">
					<el-upload
						:disabled="eiteTrue"
						:action="iphoneUrl"
						:limit="1"
						list-type="picture-card"
						:file-list="fileList1"
						:auto-upload="false"
						ref="upload1"
						accept="image/png,image/jpg,image/jpeg"
						:on-change="getiphoneUrl"
						:data="token1"
						:class="{ hide: hideUpload1 }"
						:on-preview="handlePictureCardPreview1"
						:on-remove="handleRemove1"
						:before-remove="beformRemove1"
					>
						<el-button size="small" type="primary" :class="[theme]">点击上传</el-button>
					</el-upload>
					<el-dialog v-model="addVisible1"><img :src="form.iphoneUrl" style="width: 100%;" /></el-dialog>
				</el-form-item>
				<el-form-item label="安卓二维码" prop="androidUrl" v-if="form.android">
					<el-upload
						:disabled="eiteTrue"
						:action="androidUrl"
						:limit="1"
						list-type="picture-card"
						:file-list="fileList2"
						:auto-upload="false"
						ref="upload2"
						accept="image/png,image/jpg,image/jpeg"
						:on-change="getandroidUrl"
						:class="{ hide: hideUpload2 }"
						:on-preview="handlePictureCardPreview2"
						:on-remove="handleRemove2"
						:before-remove="beformRemove2"
					>
						<el-button size="small" type="primary" :class="[theme]">点击上传</el-button>
					</el-upload>
					<el-dialog v-model="addVisible2"><img :src="dialogImageUrl2" style="width: 100%;" /></el-dialog>
				</el-form-item>
			</div>

			<el-form-item>
				<el-button size="small" type="primary" :class="[theme]" @click="oemAddForm" v-if="addBtn">添加</el-button>
				<el-button size="small" type="primary" :class="[theme]" @click="addForm">确认</el-button>
			</el-form-item>
		</el-form>
	</div>
</template>

<script>
import { OemInfo, oemAdd, updateOemInfo } from '../api/oem';
export default {
	data() {
		return {
			theme: localStorage.getItem('theme'),
			form: {
				iphone: true,
				android: true
			},
			eiteTrue: true,
			dialogImageUrl1: null,
			dialogImageUrl2: null,
			dialogImageUrl3: null,
			dialogImageUrl4: null,
			dialogImageUrl5: null,
			dialogImageUrl6: null,
			dialogImageUrl7: null,
			addVisible1: false,
			addVisible2: false,
			addVisible3: false,
			addVisible4: false,
			addVisible5: false,
			addVisible6: false,
			addVisible7: false,
			hideUpload1: false,
			hideUpload2: false,
			hideUpload3: false,
			hideUpload4: false,
			hideUpload5: false,
			hideUpload6: false,
			hideUpload7: false,
			fileList1: [],
			fileList2: [],
			fileList3: [],
			fileList4: [],
			fileList5: [],
			fileList6: [],
			fileList7: [],
			limitCount1: 1,
			limitCount2: 1,
			limitCount3: 1,
			limitCount4: 1,
			limitCount5: 1,
			limitCount6: 1,
			limitCount7: 1,
			token1: {},
			android: false,
			iphoneUrl: '',
			androidUrl: '',
			icon: '',
			loginPicture: '',
			topSmallPicture: '',
			topBigPicture: '',
			downUrl: '',
			addBtn: true
		};
	},

	created() {
		this.getOemInfo();
	},
	methods: {
		tableRowClassName({ rowIndex }) {
			if ((rowIndex + 1) % 2 === 0) {
				return 'oddRow';
			}
			return 'evenRow';
		},
		getOemInfo() {
			OemInfo(localStorage.getItem('user')).then(res => {
				if (res.code == 200) {
					this.form = res.data;
					if (res.data.webName) {
						this.addBtn = false;
					} else {
						this.addBtn = true;
					}
					if (res.data.iphoneUrl) {
						this.fileList1 = [{ url: res.data.iphoneUrl }];
						this.hideUpload1 = this.fileList1.length >= this.limitCount1;
					}
					if (res.data.androidUrl) {
						this.fileList2 = [{ url: res.data.androidUrl }];
						this.hideUpload2 = this.fileList2.length >= this.limitCount2;
					}
					if (res.data.icon) {
						this.fileList3 = [{ url: res.data.icon }];
						this.hideUpload3 = this.fileList3.length >= this.limitCount3;
					}
					if (res.data.loginPicture) {
						this.fileList4 = [{ url: res.data.loginPicture }];
						this.hideUpload4 = this.fileList4.length >= this.limitCount4;
					}
					if (res.data.topSmallPicture) {
						this.fileList5 = [{ url: res.data.topSmallPicture }];
						this.hideUpload5 = this.fileList5.length >= this.limitCount5;
					}
					if (res.data.topBigPicture) {
						this.fileList6 = [{ url: res.data.topBigPicture }];
						this.hideUpload6 = this.fileList6.length >= this.limitCount6;
					}
					if (res.data.downUrl) {
						this.fileList7 = [{ url: res.data.downUrl }];
						this.hideUpload7 = this.fileList7.length >= this.limitCount7;
					}
				} else {
					this.eiteTrue = false;
				}
			});
		},

		// 删除照片
		handleRemove1() {
			this.form.iphoneUrl = '';
		},
		handleRemove2() {
			this.form.androidUrl = '';
		},
		handleRemove3() {
			this.form.icon = '';
		},
		handleRemove4() {
			this.form.loginPicture = '';
		},
		handleRemove5() {
			this.form.topSmallPicture = '';
		},
		handleRemove6() {
			this.form.topBigPicture = '';
		},
		handleRemove7() {
			this.form.downUrl = '';
		},

		//查看大图
		handlePictureCardPreview1(file) {
			this.dialogImageUrl1 = file.url;
			this.addVisible1 = true;
		},
		handlePictureCardPreview2(file) {
			this.dialogImageUrl2 = file.url;
			this.addVisible2 = true;
		},
		handlePictureCardPreview3(file) {
			this.dialogImageUrl3 = file.url;
			this.addVisible3 = true;
		},
		handlePictureCardPreview4(file) {
			this.dialogImageUrl4 = file.url;
			this.addVisible4 = true;
		},
		handlePictureCardPreview5(file) {
			this.dialogImageUrl5 = file.url;
			this.addVisible5 = true;
		},
		handlePictureCardPreview6(file) {
			this.dialogImageUrl6 = file.url;
			this.addVisible6 = true;
		},
		handlePictureCardPreview7(file) {
			this.dialogImageUrl7 = file.url;
			this.addVisible7 = true;
		},

		// 上传照片后隐藏上传按钮
		beformRemove1(fileList) {
			this.hideUpload1 = fileList.length >= this.limitCount1;
		},
		beformRemove2(fileList) {
			this.hideUpload2 = fileList.length >= this.limitCount2;
		},
		beformRemove3(fileList) {
			this.hideUpload3 = fileList.length >= this.limitCount3;
		},
		beformRemove4(fileList) {
			this.hideUpload4 = fileList.length >= this.limitCount4;
		},
		beformRemove5(fileList) {
			this.hideUpload5 = fileList.length >= this.limitCount5;
		},
		beformRemove6(fileList) {
			this.hideUpload6 = fileList.length >= this.limitCount6;
		},
		beformRemove7(fileList) {
			this.hideUpload7 = fileList.length >= this.limitCount7;
		},

		//第一张图片
		getiphoneUrl(file, fileList) {
			this.hideUpload1 = fileList.length >= this.limitCount1;
			this.getBase64(file.raw).then(res => {
				this.form.iphoneUrl = res;
			});
		},
		getandroidUrl(file, fileList) {
			this.hideUpload1 = fileList.length >= this.limitCount2;
			this.getBase64(file.raw).then(res => {
				this.form.androidUrl = res;
			});
		},
		geticon(file, fileList) {
			this.hideUpload1 = fileList.length >= this.limitCount3;
			this.getBase64(file.raw).then(res => {
				this.form.icon = res;
			});
		},
		getloginPicture(file, fileList) {
			this.hideUpload1 = fileList.length >= this.limitCount4;
			this.getBase64(file.raw).then(res => {
				this.form.loginPicture = res;
			});
		},
		gettopSmallPicture(file, fileList) {
			this.hideUpload1 = fileList.length >= this.limitCount5;
			this.getBase64(file.raw).then(res => {
				this.form.topSmallPicture = res;
			});
		},
		gettopBigPicture(file, fileList) {
			this.hideUpload1 = fileList.length >= this.limitCount6;
			this.getBase64(file.raw).then(res => {
				this.form.topBigPicture = res;
			});
		},
		getdownUrl(file, fileList) {
			this.hideUpload1 = fileList.length >= this.limitCount7;
			this.getBase64(file.raw).then(res => {
				this.form.downUrl = res;
			});
		},
		// 转图片转vase64
		getBase64(file) {
			return new Promise(function(resolve, reject) {
				let reader = new FileReader();
				let imgResult = '';
				reader.readAsDataURL(file);
				reader.onload = function() {
					imgResult = reader.result;
				};
				reader.onerror = function(error) {
					reject(error);
				};
				reader.onloadend = function() {
					resolve(imgResult);
				};
			});
		},
		handleSearch() {
			this.form = {};
			this.dialogImageUrl1 = null;
			this.dialogImageUrl2 = null;
			this.dialogImageUrl3 = null;
			this.dialogImageUrl4 = null;
			this.dialogImageUrl5 = null;
			this.dialogImageUrl6 = null;
			this.dialogImageUrl7 = null;
			this.addVisible1 = false;
			this.addVisible2 = false;
			this.addVisible3 = false;
			this.addVisible4 = false;
			this.addVisible5 = false;
			this.addVisible6 = false;
			this.addVisible7 = false;
			this.hideUpload1 = false;
			this.hideUpload2 = false;
			this.hideUpload3 = false;
			this.hideUpload4 = false;
			this.hideUpload5 = false;
			this.hideUpload6 = false;
			this.hideUpload7 = false;
			this.fileList1 = [];
			this.fileList2 = [];
			this.fileList3 = [];
			this.fileList4 = [];
			this.fileList5 = [];
			this.fileList6 = [];
			this.fileList7 = [];
			this.eiteTrue = false;
		},
		editForm() {
			this.eiteTrue = false;
		},
		addForm() {
			let data = {
				userId: localStorage.getItem('user'),
				url: this.form.url,
				icon: this.form.icon,
				loginPicture: this.form.loginPicture,
				topSmallPicture: this.form.topSmallPicture,
				topBigPicture: this.form.topBigPicture,
				downUrl: this.form.downUrl,
				androidUrl: this.form.androidUrl,
				iphoneUrl: this.form.iphoneUrl,
				webName: this.form.webName,
				privacy: this.form.privacy,
				agreement: this.form.agreement,
				information: this.form.information,
				iphone: this.form.iphone,
				android: this.form.android,
				publicSecurity: this.form.publicSecurity,
				securityUrl: this.form.securityUrl,
				privacyAbstract: this.form.privacyAbstract,
				info: this.form.info,
				sdk: this.form.sdk
			};
			updateOemInfo(data).then(res => {
				if (res.code == 200) {
					this.getOemInfo();
					this.eiteTrue = true;
					this.$message.success('修改成功');
				} else {
					this.$message.error(res.message);
				}
			});
		},
		oemAddForm() {
			let data = {
				userId: localStorage.getItem('user'),
				url: this.form.url,
				icon: this.form.icon,
				loginPicture: this.form.loginPicture,
				topSmallPicture: this.form.topSmallPicture,
				topBigPicture: this.form.topBigPicture,
				downUrl: this.form.downUrl,
				androidUrl: this.form.androidUrl,
				iphoneUrl: this.form.iphoneUrl,
				webName: this.form.webName,
				privacy: this.form.privacy,
				agreement: this.form.agreement,
				information: this.form.information,
				iphone: this.form.iphone,
				android: this.form.android,
				publicSecurity: this.form.publicSecurity,
				securityUrl: this.form.securityUrl,
				privacyAbstract: this.form.privacyAbstract,
				info: this.form.info,
				sdk: this.form.sdk
			};
			oemAdd(data).then(res => {
				if (res.code == 200) {
					this.getOemInfo();
					this.eiteTrue = true;
					this.$message.success('添加成功');
				} else {
					this.$message.error(res.message);
				}
			});
		}
	}
};
</script>
<style>
.OEM-form {
	display: flex;
	flex-direction: column;
	padding: 0 5%;
}
.OEM-form .l {
	width: 45%;
}
.OEM-form .r {
	width: 45%;
}
</style>
